// =================  Dropdown Menu Script  ================= >>
$(document).ready(function () {
    $('.navbar a.dropdown-toggle').on('click', function (e) {
        var elmnt = $(this).parent().parent();
        if (!elmnt.hasClass('nav')) {
            var li = $(this).parent();
            var heightParent = parseInt(elmnt.css('height').replace('px', '')) / 2;
            var widthParent = parseInt(elmnt.css('width').replace('px', '')) - 10;

            if (!li.hasClass('open')) li.addClass('open')
            else li.removeClass('open');
            $(this).next().css('top', heightParent + 'px');
            $(this).next().css('left', widthParent + 'px');

            return false;
        }
    });
    $('[data-toggle="tooltip"]').tooltip();
});
// =================  Dropdown Menu Script End  ================= >>

// ================= Welcome Strip After Login Script ================= >>

    // $(window).on('scroll', function () {
    //   var navHeight = $(".header1").height();     
    //   $('.afterLgnRow').toggleClass('goToTop', $(window).scrollTop() > navHeight);
    // });
  
    // $('.afterLgnRow').affix({
    //   offset: { 
    //     top: $('.header1').offset().top 
    //   }
    // });
// $('.afterLgnRow').affix({offset: { top: $('.header1').offset().top }});
// ================= Welcome Strip After Login Script End ================= >>
